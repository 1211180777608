
function StatusPedido(st){
    switch (st){
        case "P": return "Pedido em produção";
        case "E": return "Saiu para entrega";
        case "F": return "Finalizado";
        case "A": return "Aguardando";
        default: return "";
    }
}

export {StatusPedido};