import { useEffect, useState } from "react";
import Navbar from "../../components/navbar/index.jsx";
import Pedido from "../../components/pedido/index.jsx";
import api from "../../services/api.js";
import './style.css';

function Pedidos(){

    const [pedidos, setPedidos] = useState([]);

    function ListarPedidos(){
        api.get('/admin/pedidos?status_not_in=F')
            .then(response => {            
                setPedidos(response.data);                
            })
            .catch(err => {
                console.log(err);
            });
    }

    useEffect(() => {

        ListarPedidos();

    }, []);

    return <>
        <Navbar tela="pedidos" />

        <div className="container-fluid mt-page">

            <div className="m-2 mt-4 mb-4 d-flex justify-content-between">
                <h2>Acompanhamento de Pedidos</h2>
                <button className="btn btn-outline-danger ms-4" onClick={ListarPedidos}><i className="fas fa-sync"></i>Atualizar</button>
            </div> 

            <div className="row ms-0 me-1">
                {
                    pedidos.map(function(pedido){
                        return <Pedido key={pedido.id_pedido}
                                       id_pedido={pedido.id_pedido}
                                       dt_pedido={pedido.dt_pedido}
                                       vl_total={pedido.vl_total}
                                       status={pedido.status}
                                       nome={pedido.nome}
                                       endereco={pedido.endereco}
                                       complemento={pedido.complemento}
                                       itens={pedido.itens}
                                       onClickStatus={ListarPedidos}
                                    />
                    })    
                }
            </div>
            
        </div>
        
    </>
}

export default Pedidos;