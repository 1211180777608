import { useEffect, useState } from "react";
import Modal from "react-modal/lib/components/Modal";
import closeIcone from "../../assets/close.png";
import "./style.css";
import api from "../../services/api.js";
import {NumericFormat} from "react-number-format";

function ProdutoModal(props){

    const [id_categoria, setIdCategoria] = useState(props.id_categoria);
    const [id_produto, setIdProduto] = useState(0);
    const [nome, setNome] = useState("");
    const [descricao, setDescricao] = useState("");
    const [url_foto, setUrlFoto] = useState("");
    const [vl_produto, setVlProduto] = useState(0);
    const [vl_promocao, setVlPromocao] = useState(0);
    const [ind_ativo, setIndAtivo] = useState(false);
    const [mensagem, setMensagem] = useState("");

    function HandleCategoria(e){        
        setIdCategoria(e.target.value);                                
    }

    function SalvarProduto(){
        setMensagem("");

        if (id_produto > 0){
            api.put(`/admin/produtos/${id_produto}`, {
                id_categoria,
                nome,
                descricao,
                url_foto,
                vl_produto,
                vl_promocao,
                ind_ativo: ind_ativo ? "S" : "N"
            })
            .then(retorno => {          
                if (retorno.response){
                    if (retorno.response.status != "200"){
                        //alert(retorno.response.data.erro);
                        setMensagem(retorno.response.data.erro);
                    }
                } else {
                    props.onRequestClose();
                    props.onClickSave();                                
                }
            })
            .catch(err => {        
                console.log(err) ;
                if (err.response) {
                    setMensagem(err.response.data.erro);
                } else {
                    setMensagem('Ocorreu um erro na requisição.');
                }
            });
        } else {
            api.post('/admin/produtos', {
                id_categoria,
                nome,
                descricao,
                url_foto,
                vl_produto,
                vl_promocao,
                ind_ativo: ind_ativo ? "S" : "N"                            
            })
            .then(retorno => {  
                if (retorno.response){
                    if (retorno.response.status != "200"){
                        setMensagem(retorno.response.data.erro);
                    }
                } else {          
                    props.onRequestClose();
                    props.onClickSave();
                }
            })
            .catch(err => {            
                if (err.response) {
                    setMensagem(err.response.data.erro);
                } else {
                    setMensagem('Ocorreu um erro na requisição.');
                }
            });
        }
    }

    useEffect(() => {
        setIdProduto(props.dados_produto.id_produto);
        setIdCategoria(props.dados_produto.id_categoria);
        setNome(props.dados_produto.nome);
        setDescricao(props.dados_produto.descricao);
        setUrlFoto(props.dados_produto.url_foto);
        setVlProduto(props.dados_produto.vl_produto);
        setVlPromocao(props.dados_produto.vl_promocao);
        setIndAtivo(props.dados_produto.ind_ativo == "S" ? true : false);
    }, [props.isOpen])

    return <Modal isOpen={props.isOpen}
                  onRequestClose={props.onRequestClose}
                  overlayClassName="react-modal-overlay"
                  className="react-modal-content">

                <button type="button" onClick={props.onRequestClose} className="react-modal-close">
                    <img src={closeIcone} alt="Fechar" />
                </button>

                <h4>Produto</h4>

                <div className="row">
                    <div className="col-12 mb-2">
                    <div className="form-control form-produto mb-2">
                        <select name="categoria" id="categoria" onChange={HandleCategoria} value={id_categoria}>
                            
                            {
                                props.categorias.map(c => {
                                    return <option key={c.id_categoria} value={c.id_categoria} >{c.categoria}</option>
                                })
                            }
                                                            
                        </select>                            
                    </div>
                    <div className="form-floating mb-2">
                        <input type="text" onChange={(e) => setNome(e.target.value)} value={nome} className="form-control" id="nome" placeholder="Categoria" />
                        <label htmlFor="floatingInput">Nome Produto</label>
                    </div>
                    <div className="form-floating mb-2">
                        <input type="text" onChange={(e) => setDescricao(e.target.value)} value={descricao} className="form-control" id="descricao" placeholder="Categoria" />
                        <label htmlFor="floatingInput">Descrição Produto</label>
                    </div>
                    <div className="form-floating mb-2">
                        <input type="text" onChange={(e) => setUrlFoto(e.target.value)} value={url_foto} className="form-control" id="url_foto" placeholder="Categoria" />
                        <label htmlFor="floatingInput">URL da Foto</label>
                    </div>
                    </div>                      
                </div>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-floating">
                                <NumericFormat  className="form-control mb-2" 
                                                value={props.dados_produto.vl_produto.toLocaleString('pt-br', {minimumFractionDigits: 2})} 
                                                allowLeadingZeros 
                                                decimalSeparator="," 
                                                thousandSeparator="." 
                                                decimalScale={2} 
                                                placeholder="Vl. Produto"
                                                onValueChange={(values, sourceInfo) => {
                                                    setVlProduto(values.value);
                                                }} />
                            <label htmlFor="floatingPassword">Valor Produto</label>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="form-floating">
                                <NumericFormat  className="form-control mb-2" 
                                                value={props.dados_produto.vl_promocao.toLocaleString('pt-br', {minimumFractionDigits: 2})} 
                                                allowLeadingZeros 
                                                decimalSeparator="," 
                                                thousandSeparator="." 
                                                decimalScale={2} 
                                                placeholder="Vl. Promoção"
                                                onValueChange={(values, sourceInfo) => {
                                                    setVlPromocao(values.value);
                                                }} />
                            <label htmlFor="floatingPassword">Valor Promocional</label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mb-2 mt-2">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" onClick={(e) => setIndAtivo(e.target.checked)} id="defaultCheck1" defaultChecked={ind_ativo} />
                        <label className="form-check-label" for="defaultCheck1">
                            Ativo
                        </label>
                        </div>
                    </div>
                </div>

                {mensagem.length > 0 ? <div className="alert alert-danger mt-2 text-center" role="alert">{mensagem}</div> : null}

                <div className="row">
                    <div className="col-12 mt-3 d-flex justify-content-end">
                        <div>                                                  
                            <button className="btn btn-danger ms-4" onClick={SalvarProduto}>Salvar</button>
                            <button className="btn btn-outline-danger ms-2" onClick={props.onRequestClose}>Cancelar</button>
                        </div>
                    </div>
                </div>

        </Modal>
}

export default ProdutoModal;