import "./style.css";

function Categoria(props){
    return <li className="list-group-item produto-categoria">
        <div className="m-2 d-flex justify-content-between align-items-center">
            <div>            
                <b>{props.nome}</b>
                {
                    props.ind_ativo == "N" ? <span className="badge bg-secondary ms-2">Inativo</span> : null
                }
            </div>

            <div className="dropdown">
                <a className="btn btn-outline-secondary" href="#" role="button" id={`dropdownMenuLink${props.id_categoria}`} 
                    data-bs-toggle="dropdown" aria-expanded="false">                    
                    <div className="ps-2"><i className="fas fa-ellipsis-v"></i></div>
                </a>                
                <ul className="dropdown-menu" aria-labelledby={`dropdownMenuLink${props.id_categoria}`}>
                    <li><a className="dropdown-item" onClick={(e) => props.onClickEditar(props.id_categoria)}>Editar Categoria</a></li>
                    <li><hr className="dropdown-divider" /></li>                    
                    <li><a className="dropdown-item text-danger" onClick={(e) => props.onClickExcluir(props.id_categoria)}>Excluir Categoria</a></li>                    
                </ul>
            </div>
        </div>
    </li>
}

export default Categoria;